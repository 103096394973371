<template>
    <div class="container">
        <div class="row">
            <div class="col-12 py-4">
                <div class="p-fluid container-fluid">
                    <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left"
                        @click="goBack" />
                    <!-- <h3>Country </h3> -->
                    <div style="display:flex; justify-content: space-between; ">
                        <h3>Country <strong>{{country.name}}</strong></h3>
                        <label class="mb-2 md:m-0 p-as-md-center">Is <strong>Selected</strong> Country <InputSwitch v-model="priority" style="margin-left: 0.7rem;"/> </label>
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- General info  -->
        <div class="row" style="margin-left: 20px;">
            <label for="notes">General information</label>
            <div class="field my-3">
                <Textarea
                    style="width:95%;"
                    id="generalInfo"
                    rows="5"
                    cols="30"
                    v-model="formData.information"
                />
            </div>
        </div>
        <!-- Inventory -->
        <div class="row" style="margin-left: 20px;">
            <label for="notes">Inventory</label>
            <div class="field my-3">
                <Textarea
                  style="width:95%;"
                  id="inventory"
                  rows="5"
                  cols="30"
                  v-model="formData.inventory"
                />
              </div>
        </div>
        <!-- Documents List  -->
        <div class="row" >
            <div class="col-12 py-4">
                <div class="p-fluid container-fluid">
                    <label for="Documents" class="mb-4">
                        <h4>Documents List</h4>
                    </label> 
                    <div v-if="documents !=null">
                        <DataTable
                            :value="documents"
                            responsiveLayout="scroll" 
                            showGridlines 
                            :paginator="true"
                            :rows="10" 
                            :rowsPerPageOptions="[10, 20, 50]" 
                            :rowHover="true" 
                            removableSort 
                            dataKey="id"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                        >

                        <Column 
                            :field="'title'" 
                            :header="'Title'" 
                            :key="'title'" 
                            :sortable="true"
                            style="min-width: 40rem"
                        >
                            <template #body="slotProps">
                            <div @click="openEditDocument(slotProps.data)"
                                :style="{ width: '100%', height: '100%', cursor: 'pointer' }">
                                {{ slotProps.data.title }}
                            </div>
                            </template>
                        </Column>
                        <Column
                            :field="'dateAdded'"
                            :header="'Document Added'"
                            :key="'dateAdded'"
                            bodyStyle="text-align: center"
                            :sortable="true"
                        >
                            <template #body="slotProps">
                            {{ formatDate(slotProps.data.dateAdded)  }}
                            </template>
                        </Column>

                        <Column 
                            :field="'documentDate'" 
                            :header="'Document Date'" 
                            :key="'documentDate'" 
                            bodyStyle="text-align: center"
                        >
                            <template #body="slotProps">
                            {{ formatDate(slotProps.data.documentDate) }}
                            </template>
                        </Column>

                        <Column 
                            :field="'sourceName'" 
                            :header="'Source Name'" 
                            :key="'sourceName'" 
                        >
                            <template #body="slotProps">
                            {{slotProps.data.sourceName}}
                            </template>
                        </Column>


                        </DataTable>
                    </div>
                    <div v-else style="margin-left: 20px;" >
                        <small class="p-error" >No Documents for this country </small>
                    </div>
                </div>
            </div>
        </div>
        <!-- Program List -->
        <div class="row">
            <div class="col-12 py-4">
                <div class="p-fluid container-fluid">
                    <label for="Documents" class="mb-4">
                        <h4>Programmes List</h4>
                    </label> 
                    <div v-if="programs !=null">
                        <DataTable
                            :value="programs"
                            responsiveLayout="scroll" 
                            showGridlines 
                            :paginator="true"
                            :rows="10" 
                            :rowsPerPageOptions="[10, 20, 50]" 
                            :rowHover="true" 
                            removableSort 
                            dataKey="id"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                        >
                            <Column 
                                :field="'title'" 
                                :header="'Title'" 
                                :key="'title'" 
                                :sortable="true"
                                style="min-width: 40rem"
                            >
                                <template #body="slotProps">
                                    <div @click="openEditProgram(slotProps.data)"
                                        :style="{ width: '100%', height: '100%', cursor: 'pointer' }">
                                        {{ slotProps.data.name }}
                                    </div>
                                </template>
                            </Column>
                            <Column
                                :header="'Date Added'"
                                :field="'dateAdd'"
                                :sortable="true"
                            >
                                <template #body="slotProps">
                                    <div>
                                        {{ formatDate(slotProps.data.documentProgramTopics[0].programDate) }}
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div v-else style="margin-left: 20px;" >
                        <small class="p-error" >No Programmes for this country </small>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="row">
                <div class="col-12 p-4">
                    <div class="d-flex justify-content-end px-3">
                        <Button 
                            label="Save"
                            icon="pi pi-check"
                            class="p-button-success ml-3"
                            :loading="editIsLoading"
                            @click="edit"
                        />
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import Button from 'primevue/button'
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import formatToGlobalDate from '../../../../shared/helpers/date.helper';


export default {
    name: 'EditCountryPage',
    components: {
        Button,
        Textarea,
        InputSwitch,
        DataTable,
        Column
    },
    data() {
        return {
            editIsLoading:false,
            loading: null,
            priority:false,
            formData: {
                information: null,
                inventory: null, 
            }
        }
    },
    async mounted() {
        await this.getCountryById();
        await this.getDocumentByCountryId();
        await this.getProgramsByCountryId();
    },
    computed: {
        selectedElement() {
            return this.$route.params.countryId;
        },
        country() {
            return this.$store.getters.obtainCountry;
        },
        documents() {
            return this.$store.getters.obtainDocuments
        },
        programs() {
            return this.$store.getters.obtainPrograms
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        async getCountryById() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCountry', this.selectedElement)
                this.loading = false

            } catch (error) {
                this.loading = false;
            }
        },
        async getDocumentByCountryId(){
            try {
                this.loading = true
                // change when we had the documentsByCountryId
                await this.$store.dispatch('obtainDocuments' , {countryId: this.selectedElement , companyId: null})
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        async getProgramsByCountryId(){
            try {
                this.loading = true
                await this.$store.dispatch('obtainPrograms', this.selectedElement)
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        async edit(){
            // console.log('edit ');
            try {
                this.loading=true
                const form = {
                    "countryId":this.selectedElement,
                    "countryCode":  this.country.countryCode,
                    "name":  this.country.name,
                    "hasPrioritary": this.priority,
                    "information": this.formData.information,  
                    "inventory": this.formData.inventory,
                }
                await this.$store.dispatch('modifyCountry', form );
                this.loading=false
                this.goBack();
            } catch (error) {
                this.loading=true
                console.error(error)
                this.loading=false
            }
        },
        formatDate(date) {
          return formatToGlobalDate(date);
        },
        openEditDocument(event){
            this.$router.push(`/admin/topics/documents/edit/${event.documentId}`);
        },

        async openEditProgram(event) {
            await this.$store.dispatch('updateSelectedTopic', event.documentProgramTopics[0].topicId);
            this.$router.push(`/admin/topics/program/overview/${event.documentProgramTopics[0].documentProgramTopicId}`);
        },
    },
    watch: {
        country(newVal) {
            if (newVal) {
                (newVal.hasPrioritary) 
                ? this.priority = true
                : this.priority = false
                this.formData.information = newVal.information
                this.formData.inventory = newVal.inventory
            }
        },
    },
}
</script>